import React from "react"
import useTranslations from '../../components/useTranslations';
import LocalizedLink from '../../components/LocalizedLink'
import { LocaleContext } from '../Layout'
import * as S from "./commercialStyled"
import marked from 'marked'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
const Commercial = () => {

  const {
    landingPage,
    navbar
  } = useTranslations();

  const { locale } = React.useContext(LocaleContext)
  const energyDashboardTR = "https://res.cloudinary.com/konzek/image/upload/v1602147641/Retmon/landing-page/enerji-tuketimi-tr.svg"
  const energyDashboardENG = "https://res.cloudinary.com/konzek/image/upload/v1602147641/Retmon/landing-page/enerji-tuketimi-eng.svg"
  const energyDashboard = locale === 'tr' ? energyDashboardTR : energyDashboardENG

  const alarmDashboardTR = "https://res.cloudinary.com/konzek/image/upload/v1602147524/Retmon/landing-page/alarm-tr.svg"
  const alarmDashboardENG = "https://res.cloudinary.com/konzek/image/upload/v1602147524/Retmon/landing-page/alarm-eng.svg"
  const alarmDashboard = locale === 'tr' ? alarmDashboardTR : alarmDashboardENG
  const dashboardVideo = "https://res.cloudinary.com/konzek/video/upload/v1595404213/Retmon/landing-page/retmon-ui_oznejp.mp4"

  return (
    <>
      <S.GridRow>
        <S.Col>
          <img
            alt={landingPage.watchWarn}
            title={landingPage.watchWarn}
            data-srcset="
         https://res.cloudinary.com/konzek/image/upload/v1602061653/Retmon/landing-page/datacenter_1.jpg 500w,
         https://res.cloudinary.com/konzek/image/upload/v1602061653/Retmon/landing-page/datacenter_1.jpg 640w,
         https://res.cloudinary.com/konzek/image/upload/v1602061653/Retmon/landing-page/datacenter_1.jpg 1024w"
            data-src="medium"
            style={{
              borderRadius: '5px',
              width: '100%'
            }}
            className="lazyload" />
          <img src={energyDashboard} alt='Enerji Yönetimi'
            className='energy-dashboard' />
        </S.Col>
        <S.Col>
          <S.Title>{landingPage.manageAllData}</S.Title>
          <S.Text>
            <div dangerouslySetInnerHTML={{ __html: marked(landingPage.manageAllDataDesc) }} /></S.Text>
          <S.ButtonGroup>
            <LocalizedLink to={`/${navbar.solutionsurl}`}>
              <S.ButtonOutline>
                {landingPage.getProducts}
              </S.ButtonOutline>
            </LocalizedLink>
          </S.ButtonGroup>
        </S.Col>
      </S.GridRow>

      <S.RowReverse>
        <S.Col>
          <S.Title>{landingPage.commercialSecond_Title}</S.Title>
          <S.Text>
            <div dangerouslySetInnerHTML={{ __html: marked(landingPage.commercialSecond_Desc) }} />
          </S.Text>
        </S.Col>
        <S.Col>
          <img
            alt={landingPage.watchWarn}
            title={landingPage.watchWarn}
            data-srcset={alarmDashboard}
            data-src="medium"
            style={{
              borderRadius: '5px',
              width: '100%'
            }}
            className="lazyload" />
        </S.Col>
      </S.RowReverse>

      <S.GridRow>
        <S.Col>
          <video
            alt={landingPage.watchWarn}
            title={landingPage.watchWarn}
            autoPlay
            src={dashboardVideo}
            style={{
              borderRadius: '5px',
              width: '100%'
            }}
            className="lazyload" />
        </S.Col>
        <S.Col>
          <S.Title>{landingPage.commercialLast_Title}
          </S.Title>
          <S.Text>
            <div dangerouslySetInnerHTML={{ __html: marked(landingPage.commercialLast_Desc) }} />
          </S.Text>
        </S.Col>
      </S.GridRow>
    </>
  )
};

export default Commercial;
