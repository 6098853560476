import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const GridRow = styled.div`
display: grid;
gap: 2rem;
height: 50vh;
margin-bottom: 5rem;
align-items: center;
grid-template-columns: 1fr 1fr;
${media.lessThan('medium')`
width:100%;
grid-template-columns: 1fr;
height: auto;
`}
`;


export const RowReverse = styled.div`
display: grid;
gap: 2rem;
height: 50vh;
align-items: center;
grid-template-columns: 1fr 1fr;
${media.lessThan('medium')`
height: auto;
width: 100%;
margin-top: 5rem;
margin-bottom: 5rem;
display:flex;
flex-wrap: wrap-reverse;
`}
`;


export const Col = styled.div`
${media.lessThan('medium')`
width:100%;
`}
`;

export const Title = styled.h2`
font-size:${props => (props.size || 'var(--h2)')};
font-weight: var(--bold);
margin-bottom: 1rem;
&:after{
  border-left: 1.5em solid var(--red);
  border-left-color: var(--red);
  display: block;
  content: "";
  height: 0.2rem;
  margin-top: 0.2em;
}
`;


export const Text = styled.p`
  font-size:  var(--textBase);
  color: var(--secondary);
`


export const ButtonGroup = styled.div`
align-items: center;
display: flex;
flex-wrap: wrap;
`

export const Button = styled.button`
  background: ${props => props.Color || 'var(--secondary)'};
  padding: 0.8rem 1rem;
  margin: 0 1.125em 1.125em 0;
  width: auto;
  border-radius: 0.2rem;
  color: white;
  outline: none;
  border: none;
  display:block;
  margin-top: 1rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 100;
  line-height: 1.25;
  padding: .625em 1.5em;
  text-align: center;
  text-decoration: none;
  font-size: var(--textBase);
  &:hover {
    background: var(--secondaryHover); 
  }
  &:active,
  &:focus {
    background: var(--secondaryHover); 
  }
`
export const ButtonOutline = styled.button`
  border: 1px solid var(--secondary);
  color:  var(--secondary);
  background: none;
  padding: 0.8rem 1rem;
  margin: 0 1.125em 1.125em 0;
  width: auto;
  border-radius: 0.2rem;
  outline: none;
  display: block;
  margin-top: 1rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 100;
  line-height: 1.25;
  padding: .625em 1.5em;
  text-align: center;
  text-decoration: none;
  font-size: var(--textBase);
  &:hover {
    border: 1px solid var(--secondaryHover);
  }
  &:active,
  &:focus {
    border: 1px solid var(--secondaryHover);
  }
`
