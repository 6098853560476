import React from "react"
import SolutionBMS from "../../images/solutions/BMS.svg"
import SolutionDCIM from "../../images/solutions/DCIM.svg"
import SolutionEMS from "../../images/solutions/EMS.svg"
import SolutionPMS from "../../images/solutions/PMS.svg"
import SolutionIOT from "../../images/solutions/iot.svg"
import useTranslations from '../../components/useTranslations';
import LocalizedLink from '../LocalizedLink/index';
import marked from 'marked'
import * as S from "./styled"

const Solutions = () => {
  const {
    landingPage,
    navbar,
    updated,
    solutionsPage
  } = useTranslations();

  return (
    <S.Row>
      <S.BorderedColumn>
        <S.Title as='h2' id='solutions'>{solutionsPage.title}</S.Title>
        <S.FlexRow>
          <S.Text>
            <div dangerouslySetInnerHTML={{ __html: marked(landingPage.solutionsDesc) }} />
          </S.Text>
        </S.FlexRow>
        <S.Column>
          <S.SolutionIcons>
            <LocalizedLink to='/ems'>
              <S.CardImages src={SolutionEMS} alt='Retmon EMS Icon' />
              <S.SolutionTitle>{navbar.solutionsSub.ems}</S.SolutionTitle>
            </LocalizedLink>
            <LocalizedLink to='/dcim'>
              <S.CardImages src={SolutionDCIM} alt='Retmon DCIM Icon' />
              <S.SolutionTitle>{navbar.solutionsSub.dcim}</S.SolutionTitle>
            </LocalizedLink>
            <LocalizedLink to='/bms'>
              <S.CardImages src={SolutionBMS} alt='Retmon BMS Icon' />
              <S.SolutionTitle>{navbar.solutionsSub.bms}</S.SolutionTitle>
            </LocalizedLink>
            <LocalizedLink to='/pms'>
              <S.CardImages src={SolutionPMS} alt='Retmon PMS Icon' />
              <S.SolutionTitle>{navbar.solutionsSub.pms}</S.SolutionTitle>
            </LocalizedLink>
            <LocalizedLink to='/iot'>
              <S.CardImages src={SolutionIOT} alt='Retmon IOT Icon' />
              <S.SolutionTitle>{navbar.solutionsSub.iot}</S.SolutionTitle> <S.Badge>{updated}</S.Badge>
            </LocalizedLink>
          </S.SolutionIcons>
        </S.Column>
        <S.FlexRow>
          <LocalizedLink to={`/${navbar.solutionsurl}/`}>
            <S.RetmonCTAButton Width='12rem'>{landingPage.more}</S.RetmonCTAButton>
          </LocalizedLink>
        </S.FlexRow>
      </S.BorderedColumn>
    </S.Row>
  )
};

export default Solutions;
