import React from 'react'
import useTranslations from '../../components/useTranslations'
import * as S from '../../components/Form/styled'
import * as F from "./styled"
import LocalizedLink from '../LocalizedLink'

const Feedback = () => {
  const {
    request,
    landingPage
  } = useTranslations()

  return (
      <F.BorderedColumn 
      className='feedback'
      style={{
        display: 'flex',
        marginTop: '5rem',
        marginBottom: '5rem'
      }}>

        <S.FeedbackColumn width="100%">
          <F.Title as="h3">{landingPage.askPriceTitle}</F.Title>
          <S.Text>
            {landingPage.askPriceDescription}
          </S.Text>
          <LocalizedLink to={`/${request}/`}>
            <F.RetmonCTAButton width="12rem">
              {landingPage.offerButton}
            </F.RetmonCTAButton>
          </LocalizedLink>
        </S.FeedbackColumn>
        <F.FeedbackIcon
          src='https://res.cloudinary.com/konzek/image/upload/v1612765512/Retmon/landing-page/nuhetv66jnciwvtzfjv3.webp'
          alt='Retmon Sensör'
        />
      </F.BorderedColumn>
  )
}

export default Feedback
