import React from 'react'
import LocalizedLink from '../LocalizedLink'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'
import useTranslations from '../../components/useTranslations'
import {
  FlexCardImages,
  CardImages,
  CardImageWrapperLanding,
} from '../../components/Referances/styled'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import '../../components/CSS/slick.css'
import TestimonialsMainData from '../../data/testimonails.yaml'
import { LocaleContext } from '../Layout'

const ReferancesLP = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  }

  const { locale } = React.useContext(LocaleContext)
  const Testimonails = TestimonialsMainData
  const Data = locale === 'tr' ? Testimonails.tr : Testimonails.en
  const { landingPage, navbar } = useTranslations()

  const data = useStaticQuery(graphql`
    {
      allDataYaml {
        edges {
          node {
            referances {
              imageAlt
              landing
              image {
                childImageSharp {
                  fixed(width: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              title
              companyUrl
            }
          }
        }
      }
    }
  `)
  const referancesLimit = 12
  const referancesAlldata =
    data.allDataYaml.edges[0].node.referances ||
    data.allDataYaml.edges[1].node.referances
  const referancesFiltered = referancesAlldata
    .filter(item => item.landing === true)
    .slice(0, referancesLimit)

  return (
    <S.BorderedColumn style={{ paddingBottom: '4rem' }}>
      <S.Title as="h2">{landingPage.ourReferances}</S.Title>
      <S.FlexRow>
        <FlexCardImages>
          {referancesFiltered.map(referance => (
            <CardImageWrapperLanding>
              <CardImages
                key={referance.imageAlt}
                fixed={referance.image.childImageSharp.fixed}
                alt={referance.imageAlt}
              />
            </CardImageWrapperLanding>
          ))}
        </FlexCardImages>
      </S.FlexRow>
      <S.FlexRow>
        <LocalizedLink to={`/${navbar.refURL}/`}>
          <S.RetmonCTAButton Width="12rem">
            {landingPage.seeReferances}
          </S.RetmonCTAButton>
        </LocalizedLink>
      </S.FlexRow>
      <div className="stroke-divider" />
      <Slider {...settings}>
        {Data.items.map(data => (
          <div className="testimonial-wrapper">
            <div className="testimonial-logo-section">
              {data.companyLogoURL ? (
                <img
                  src={data.companyLogoURL}
                  alt={data.companyName}
                  className="testimonial-logo"
                />
              ) : (
                <>
                  <img
                    src="https://res.cloudinary.com/konzek/image/upload/v1602253144/Retmon/web/retmon-icon-default.jpg"
                    alt="Retmon"
                    className="testimonial-logo"
                  />
                  <text>{data.companyName}</text>
                </>
              )}
              <p dangerouslySetInnerHTML={{ __html: data.userQuote }} />
              <div className="testimonial-customer-section">
                <div className="customer-avatar-wrapper">
                  {data.userAvatarURL ? (
                    <img
                      src={data.userAvatarURL}
                      alt={data.userName}
                      className="customer-avatar"
                    />
                  ) : (
                    <img
                      src="https://res.cloudinary.com/konzek/image/upload/v1595937579/Konzek/teams/tctjjtj8qc3lwot8ffza.jpg"
                      alt={data.userName}
                      className="customer-avatar"
                    />
                  )}
                </div>
                <div className="customer-info">
                  <text>{data.userName}</text>
                  <span>{data.userTitle}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </S.BorderedColumn>
  )
}

export default ReferancesLP
