import React from 'react'
import SEO from '../components/seo'
import useTranslations from '../components/useTranslations'
import Referances from '../components/LandingPage/referances'
import Solutions from '../components/LandingPage/solutions'
import HeroSection from '../components/LandingPage/heroSection'
import Commercial from '../components/LandingPage/commercial'
import Feedback from '../components/LandingPage/feedback'
import BlogSection from '../components/LandingPage/blogSection'
import { DividerForRow } from '../components/LandingPage/styled'
import { Container } from '../components/Layout/styled'
// import VideoCommercial from "../components/LandingPage/Videocommercial"
// import DownloadSection from "../components/LandingPage/downloadsection"
// import Counting from "../components/LandingPage/counting"
// import DocumentDownlad from "../components/LandingPage/documentDownload"
// import Contact from "../components/LandingPage/contact"

const Index = () => {
  const { retmonTitle, retmonDescription, lang } = useTranslations()
  return (
    <>
      <div className="homepage">
        <SEO title={retmonTitle} description={retmonDescription} lang={lang} />
        <HeroSection />
        <Container>
          <Commercial />
          {/* <VideoCommercial /> */}
          {/* <DividerForRow /> */}
          {/* <DownloadSection /> */}
          {/* <DividerForRow /> */}
          <Solutions />
          {/* <DocumentDownlad /> */}
          <DividerForRow />
          {/* <Counting /> */}
          <Referances />
          <DividerForRow />
          <BlogSection />
          <DividerForRow />
          <Feedback />
          <DividerForRow />
          {/* <Contact /> */}
        </Container>
      </div>
    </>
  )
}

export default Index
