import React from 'react'
import LocalizedLink from '../LocalizedLink'
import useTranslations from '../useTranslations'
import { LocaleContext } from '../Layout'
import marked from 'marked'
import * as S from './styled'

export default () => {
  const { request, landingPage, navbar } = useTranslations()
  const { locale } = React.useContext(LocaleContext)
  const dashboardTR = "https://res.cloudinary.com/konzek/image/upload/v1612179116/Retmon/web/dashboard-tr.svg"
  const dashboardENG = "https://res.cloudinary.com/konzek/image/upload/v1612179116/Retmon/web/dashboard-en.svg"
  const dashboard = locale === 'tr' ? dashboardTR : dashboardENG

  return (
    <>
      <img className='hero-bg-square' src='https://res.cloudinary.com/konzek/image/upload/v1601987241/Retmon/landing-page/retmon-square.svg' />
      <S.Relative>
        <S.HeroContent>
          <S.Title className='hero-title' size='4rem'>
            <div dangerouslySetInnerHTML={{ __html: (landingPage.title_2) }} />
          </S.Title>
          <S.Text>
            <div dangerouslySetInnerHTML={{ __html: marked(landingPage.findYour) }} /></S.Text>
          <S.ButtonGroup>
            <LocalizedLink to={`/${request}`}>
              <S.Button>
                {landingPage.freeDemo}
              </S.Button>
            </LocalizedLink>
          </S.ButtonGroup>
        </S.HeroContent>
      </S.Relative>
      <S.HeroImage
        alt={landingPage.title_1}
        title={landingPage.title_1}
        data-srcset={dashboard}
        data-src="medium"
        className="lazyload" />
    </>
  )
}
