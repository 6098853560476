import React from 'react'
import LocalizedLink from '../LocalizedLink'
import { StaticQuery, graphql } from 'gatsby'
import * as S from './styled'
import useTranslations from '../../components/useTranslations'
import { LocaleContext } from '../Layout';

export default () => {
  const { landingPage, navbar } = useTranslations()
  const { locale } = React.useContext(LocaleContext);
  return (
    <StaticQuery
      query={graphql`
        query BlogSection {
          allMarkdownRemark(
            filter: { frontmatter: { page: { eq: "post" } } }
            limit: 6
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                  imageURL
                  date
                }
                excerpt(format: PLAIN, pruneLength: 150)
                fields {
                  slug
                }
              }
            }
          }
        }
      `}


      render={data => {
        return (
          <S.BorderedColumn>
            <S.Title as='h2'>Blog</S.Title>
            <S.BlogGrid>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <S.BlogCol href={`blog/${node.fields.slug}`} title={node.frontmatter.title}>
                  <S.BlogImg
                    alt={node.frontmatter.title}
                    title={node.frontmatter.title}
                    data-srcset={`${node.frontmatter.imageURL} 500, ${node.frontmatter.imageURL} 640w, ${node.frontmatter.imageURL} 1024w`}
                    data-src="medium"
                    className="lazyload" />
                  <S.BlogHeader>{node.frontmatter.title}</S.BlogHeader>
                  <S.BlogDesc>{node.excerpt}</S.BlogDesc>
                  <S.BlogDate>{node.frontmatter.date}</S.BlogDate>
                </S.BlogCol>
              ))}
            </S.BlogGrid>
            <S.FlexRow>
              <LocalizedLink to={'/blog'}>
                <S.RetmonCTAButton Width="12rem">
                  {landingPage.allBlogs}
                </S.RetmonCTAButton>
              </LocalizedLink>
            </S.FlexRow>
          </S.BorderedColumn>
        )
      }}
    />
  )
}
